import { graphql, PageProps } from "gatsby";
import React from "react";

import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";

const LegalData = ({ data }: PageProps<Queries.LegalDataQuery>) => {
  return (
    <Layout>
      <div className="docs">
       <h1><strong>FORMULARIO: Consentimiento para el env&iacute;o de CURR&Iacute;CULUM</strong></h1>
<h2><strong>INFORMACI&Oacute;N PARA EL TRATAMIENTO DE DATOS PERSONALES</strong></h2>
<p><strong>CON FINALIDAD DE CANDIDATO A EMPLEADO</strong></p>
<p>D.I.R.E USIETO, S.A. es el <strong>Responsable del tratamiento</strong> de los datos personales del interesado y le informa de que estos datos se tratar&aacute;n de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Org&aacute;nica 3/2018, de 5 de diciembre (LOPDGDD), por inter&eacute;s leg&iacute;timo del Responsable, facilit&aacute;ndole la siguiente informaci&oacute;n del tratamiento:</p>
<p><strong>Fines del tratamiento</strong>: hacer part&iacute;cipe al interesado en los procesos de selecci&oacute;n de personal, llevando a cabo un an&aacute;lisis del perfil del solicitante con el objetivo de seleccionar al mejor candidato para el puesto vacante del Responsable.</p>
<p><strong>Legitimaci&oacute;n del tratamiento</strong>: consentimiento inequ&iacute;voco mediante una clara acci&oacute;n del interesado (GDPR, art. 6.1.a).</p>
<p><strong>Registro de curr&iacute;culums</strong>: le informamos de que este es el &uacute;nico procedimiento oficial para aceptar su curr&iacute;culum, por lo que no se aceptar&aacute;n los curr&iacute;culums remitidos por otro procedimiento diferente.</p>
<p><br /><strong>Criterios de conservaci&oacute;n de los datos</strong>: se conservar&aacute;n durante un plazo m&aacute;ximo de <strong>un a&ntilde;o</strong>, transcurrido el cual se proceder&aacute; a la supresi&oacute;n de los datos garantiz&aacute;ndole un total respeto a la confidencialidad, tanto en el tratamiento como en su posterior destrucci&oacute;n. En este sentido, transcurrido el citado plazo, y si desea continuar participando en los procesos de selecci&oacute;n del Responsable, le rogamos nos remita nuevamente su curr&iacute;culum.</p>
<p><strong>Actualizaci&oacute;n de los datos</strong>: En caso de producirse alguna modificaci&oacute;n en sus datos, le rogamos nos lo comunique por escrito lo antes posible, con objeto de mantener sus datos debidamente actualizados.</p>
<p><strong>Comunicaci&oacute;n de los datos</strong>: se podr&aacute;n tratar y/o comunicar a las empresas integrantes de nuestro grupo (cuyas direcciones figuran en esta misma p&aacute;gina web), durante el tiempo de conservaci&oacute;n de su curr&iacute;culum y para los mismos fines antes informados.</p>
<p><strong>Derechos que asisten al Interesado</strong>:</p>
<ul>
<li>Derecho a retirar el consentimiento en cualquier momento.</li>
<li>Derecho de acceso, rectificaci&oacute;n, portabilidad y supresi&oacute;n de sus datos y de limitaci&oacute;n u oposici&oacute;n asu tratamiento.</li>
<li>Derecho a presentar una reclamaci&oacute;n ante la Autoridad de control (www.aepd.es) si considera que eltratamiento no se ajusta a la normativa vigente.</li>
</ul>
<p><strong>Datos de contacto para ejercer sus derechos: </strong>D.I.R.E USIETO, S.A.<strong>. </strong>C/ PERTUSA, 16 - 50197 ZARAGOZA (Zaragoza). E-mail: info@grupousieto.com</p>
<p><strong>D.I.R.E USIETO, S.A. </strong>P. 1 C/ PERTUSA, 16 - 50197 ZARAGOZA (Zaragoza)</p>
<p>Para continuar, debe aceptar que ha le&iacute;do y est&aacute; conforme con la cl&aacute;usula anterior.</p>
      </div>
    </Layout>
  );
};

export default LegalData;

export const Head = () => <SEO />;

export const query = graphql`
  query LegalData {
    sanityDocumentData {
      globals {
        email
        phone
      }
      foodService {
        phone
        email
      }
      logistics {
        email
        email
      }
      legalName
      urlSite
    }
  }
`;
